import pick from 'lodash/pick';

import config from '../../config';
import { types as sdkTypes, util as sdkUtil, createImageVariantConfig } from '../../util/sdkLoader';
import { getStartOf, addTime } from '../../util/dates';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  queryUsersByBookmark,
  transactionLineItems,
  updateOfferSeenNotification,
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE, TRANSITION_SEND_OFFER } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

import { isArrayLength, isRentalListing, getParentId, getSizes } from '../../util/dataExtractors';

const { UUID } = sdkTypes;

const extraParams = {
  include: ['author', 'author.bio', 'author.profileImage', 'images', 'currentStock'],
  'fields.listing': ['title', 'description', 'price', 'publicData'],
  'fields.user': [
    'profile.displayName',
    'profile.abbreviatedName',
    'profile.publicData',
    'profile.bio',
  ],
  'fields.image': [
    // Scaled variants for large images
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Cropped variants for listing thumbnail images
    `variants.listing-card`,
    `variants.listing-card-2x`,
    `variants.listing-card-4x`,
    `variants.listing-card-6x`,

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  ...createImageVariantConfig(`listing-card`, 400, 1),
  ...createImageVariantConfig(`listing-card-2x`, 800, 1),
  ...createImageVariantConfig(`listing-card-4x`, 1600, 1),
  ...createImageVariantConfig(`listing-card-6x`, 2400, 1),
};

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const SEARCH_AUTHOR_LISTINGS = 'app/ListingPage/SEARCH_AUTHOR_LISTINGS';
export const SEARCH_AUTHOR_DESCRIPTION_SUCCESS =
  'app/ListingPage/SEARCH_AUTHOR_DESCRIPTION_SUCCESS';
export const SEARCH_SIMILAR_LISTINGS = 'app/ListingPage/SEARCH_SIMILAR_LISTINGS';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SAVE_COUNT_RESPONSE = 'app/ListingPage/SAVE_COUNT_RESPONSE';

export const SAVE_LISTING_SIZES = 'app/ListingPage/SAVE_LISTING_SIZES';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  authorListingsIds: [],
  similarListingsIds: [],
  sizesListingsIds: [],
  authorDescription: '',
  enquiryModalOpenForListingId: null,
  saveCountBySeller: 0,
};

const resultIds = data => data?.map(l => l?.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_COUNT_RESPONSE:
      return { ...state, saveCountBySeller: payload };
    case SAVE_LISTING_SIZES:
      return { ...state, sizesListingsIds: resultIds(payload) };
    case SEARCH_AUTHOR_LISTINGS:
      return { ...state, authorListingsIds: resultIds(payload), showListingError: null };
    case SEARCH_SIMILAR_LISTINGS:
      return { ...state, similarListingsIds: resultIds(payload), showListingError: null };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };
    case SEARCH_AUTHOR_DESCRIPTION_SUCCESS:
      return { ...state, authorDescription: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Selectors ================ //

export const selectAuthorDescription = state => state.ListingPage.authorDescription;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const saveListingSizes = payload => ({ type: SAVE_LISTING_SIZES, payload });

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const saveCountResponse = payload => ({
  type: SAVE_COUNT_RESPONSE,
  payload,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const searchAuthorListingsSuccess = payload => ({
  type: SEARCH_AUTHOR_LISTINGS,
  payload,
});
export const searchAuthorDescriptionSuccess = payload => ({
  type: SEARCH_AUTHOR_DESCRIPTION_SUCCESS,
  payload: payload?.authorDescription,
});
export const searchSimilarListingsSuccess = payload => ({
  type: SEARCH_SIMILAR_LISTINGS,
  payload,
});

// ================ Thunks ================ //

export const fetchSizes = (listing, isOwn = false) => (dispatch, getState, sdk) => {
  const parentId = getParentId(listing);

  const sizes = getSizes(listing);
  const sizesListingsIds = isArrayLength(sizes) ? sizes.map(s => s?.id) : null;

  // Early return condition
  if (!parentId && (!sizesListingsIds || sizesListingsIds?.length === 0)) {
    // Optionally, you can dispatch an action or log a message before returning
    console.log('No parentId or valid sizes array length. Skipping query.');
    return Promise.resolve({ message: 'No query needed.' });
  }

  const params = parentId
    ? { pub_parentId: parentId }
    : { ids: [listing?.id?.uuid, ...sizesListingsIds] };

  return sdk.listings
    .query({ ...extraParams, minStock: 1, ...params })
    .then(async response => {
      const listings = response?.data?.data || [];
      dispatch(addMarketplaceEntities(response));
      dispatch(saveListingSizes(listings));
      return response;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
      // In case of an error, it might be helpful to still resolve the promise with a default value or the error itself,
      // so the calling function can handle it accordingly.
      return Promise.resolve({ error: e, message: 'Error fetching sizes.' });
    });
};

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    ...extraParams,
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(async data => {
      const authorId = isArrayLength(data?.data?.included)
        ? data?.data?.included?.find(d => d?.type === 'user')
        : null;
      const listing = data?.data?.data;
      const isRental = isRentalListing(listing);
      if (isRental) {
        dispatch(fetchTimeSlots(listingId));
      }
      dispatch(addMarketplaceEntities(data));
      dispatch(fetchSizes(listing));
      dispatch(fetchSimilarListings(listing));

      if (authorId && authorId?.id) {
        dispatch(fetchAuthorListings(authorId?.id));
      }
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchAuthorListings = authorId => async (dispatch, getState, sdk) => {
  try {
    const userResponse = await sdk.users.show({ id: authorId });
    const authorDescription = userResponse?.data?.data?.attributes?.profile?.bio;
    dispatch(searchAuthorDescriptionSuccess({ authorDescription }));

    const listingsResponse = await sdk.listings.query({
      ...extraParams,
      authorId,
      perPage: 50,
      minStock: 1,
    });

    dispatch(addMarketplaceEntities(listingsResponse));
    dispatch(searchAuthorListingsSuccess(listingsResponse?.data?.data));
  } catch (error) {
    console.error('Error fetching author listings:', error);
  }
};

export const fetchSimilarListings = listing => async (dispatch, getState, sdk) => {
  const publicData = listing?.attributes?.publicData;
  if (!publicData) {
    return null;
  }

  try {
    const queries = [
      { field: 'pub_size', value: publicData?.size },
      { field: 'pub_color', value: publicData?.color },
      { field: 'pub_brand', value: publicData?.brand },
    ];

    const fetchListings = queries.map(async query => {
      const listings = await sdk.listings.query({
        ...extraParams,
        [query.field]: query.value,
        minStock: 1,
        perPage: 20,
        sort: '-createdAt',
      });
      dispatch(addMarketplaceEntities(listings));
      return listings.data;
    });

    const combinedListings = (await Promise.all(fetchListings))?.map(e => e.data)?.flat();
    const listings = isArrayLength(combinedListings)
      ? combinedListings.filter((v, i, a) => a?.findIndex(v2 => v2?.id?.uuid === v?.id?.uuid) === i)
      : [];
    dispatch(searchSimilarListingsSuccess(listings));
    const countResponse = await queryUsersByBookmark({ id: listing?.id?.uuid });
    dispatch(saveCountResponse(countResponse?.totalCount || 0));
    return combinedListings;
  } catch (error) {
    return null;
  }
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage', 'author.publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const now = new Date();
  const start = getStartOf(now, 'day', 'Etc/UTC');
  const end = addTime(start, timeSlotsRange, 'days', 'Etc/UTC');
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: addTime(end, secondRange, 'days', 'Etc/UTC'),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (params, message, offerParams = null) => (dispatch, getState, sdk) => {
  const { listingId } = params;
  dispatch(sendEnquiryRequest());
  const offerPrice = offerParams?.offerPrice;
  const processAlias = offerPrice ? config.offerProcessAlias : config.transactionProcessAlias;
  const bodyParams = {
    transition: offerPrice ? TRANSITION_SEND_OFFER : TRANSITION_ENQUIRE,
    processAlias,
    params: { listingId },
  };

  if (offerPrice) {
    Object.assign(bodyParams.params, {
      protectedData: {
        offerParams,
      },
    });
  }

  return sdk.transactions
    .initiate(bodyParams)
    .then(async response => {
      const transactionId = response.data.data.id;
      // Send the message to the created transaction

      if (!offerPrice) {
        await sdk.messages.send({ transactionId, content: message });
      }

      // if (offerPrice) {
      //   await updateOfferSeenNotification({
      //     id: transactionId?.uuid,
      //     role: 'buyer',
      //   });
      // }

      dispatch(sendEnquirySuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return transactionId;
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());

  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.listingManagementType === 'availability') {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(fetchReviews(listingId)),
    ]);
  } else {
    return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]);
  }
};

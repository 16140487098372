import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { IconHeartFilled, IconHeart, ImageSlider, AvatarSmall, NamedLink } from '../../components';
import {
  getBrandName,
  getLikes,
  getLikesCount,
  getProductImages,
  getRecentlyViewed,
  getStoreName,
  getUsername,
  getWishlist,
  getBookmarks,
  isArrayLength,
  getGTMParams,
  getMailChimpParams,
  isRentalSearchPage,
} from '../../util/dataExtractors';
import IconBag from '../IconBag/IconBag';
import IconBagFilled from '../IconBagFilled/IconBagFilled';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import routeConfiguration, { draftSlug } from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import config from '../../config';
import { useMyContext } from '../../contexts/StateHolder';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookmarks, selectCurrentUser, updateSave } from '../../ducks/user.duck';
import {
  getKeyFromLocalStorage,
  isWindowDefined,
  setLocalStorageItems,
} from '../../util/cartHelpers';
import { pushEventToDataLayer } from '../../util/gtm';
import { GOOGLE_TAGS } from '../../config/enums';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { selectIsAuthenticated } from '../../ducks/Auth.duck';
import { searchListings } from '../../containers/CartPage/CartPage.duck';
import { addStoreProductMailChimp, removeStoreProductMailChimp } from '../../ducks/Global.duck';

import css from './ListingCard.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const ListingCardComponent = props => {
  const {
    brandText,
    conditionText,
    sizeText,
    className,
    rootClassName,
    listing,
    showAuthorInfo,
    isOwnListing = false,
    disableProfileLink = false,
  } = props;

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const history = useHistory();
  const dispatch = useDispatch();
  const [cacheCount, setCacheCount] = useState(0); // This is used to show count +1 or -1 directly to users to avoid lag
  const { setCartItems } = useMyContext();

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing?.id?.uuid;
  const { title = '', price, publicData } = currentListing?.attributes || {};

  const slug = createSlug(title || draftSlug);
  const author = ensureUser(listing.author);
  const storeName = getStoreName(author);
  const wishlist = getWishlist(currentUser);
  const likes = getLikes(currentListing);
  const likesCount = getLikesCount(currentListing);
  const recentlyViewed = getRecentlyViewed(currentUser) || [];

  const photos = publicData && publicData.photos;
  const firstPhoto = photos && photos.length && photos[0];
  const isSyncedWithMC = publicData?.isSyncedWithMC;

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateRecentlyViewed = () => {
    if (isAuthenticated) {
      recentlyViewed.push(id);
      const viewed = [...new Set(recentlyViewed)];
      Array.isArray(viewed) && viewed.reverse();
      const slicedViews = isArrayLength(viewed) ? viewed.slice(0, 11) : viewed;
      onUpdateProfile({
        publicData: {
          recentlyViewed: slicedViews,
        },
      });
    }
  };

  const productPrice = price && price.amount / 100;
  const username = getUsername(author);
  const authorDisplayName = author && userDisplayNameAsString(author, '');
  const display = storeName ? storeName : authorDisplayName;

  const authorLink =
    author && author.id && !disableProfileLink ? (
      <NamedLink
        className={css.authorNameLink}
        name={username ? 'StorePage' : 'ProfilePage'}
        params={username ? { username: username || author?.id?.uuid } : { id: author?.id?.uuid }}
        to={{ state: author?.id?.uuid }}
      >
        {display}
      </NamedLink>
    ) : (
      <span className={css.authorName}>{display}</span>
    );

  const images = firstPhoto ? photos : getProductImages(currentListing.images);

  const localBookmarks = getKeyFromLocalStorage('localBookmarks');

  const bookmarks = isAuthenticated ? getBookmarks(currentUser) : localBookmarks;

  const tagParams = getGTMParams(listing, currentUser);

  const onUpdateCurrentUser = (array, isLikes = false, index) => {
    pushEventToDataLayer(
      tagParams,
      index > -1 ? GOOGLE_TAGS.REMOVE_FROM_CART : GOOGLE_TAGS.ADD_TO_CART
    );
    const params = isLikes
      ? { wishlist: Array.from(new Set(array)) }
      : { bookmarks: Array.from(new Set(array)) };

    onUpdateProfile({
      publicData: { ...params },
    }).then(() => {
      dispatch(fetchBookmarks());
      dispatch(searchListings());
    });
  };

  const productParams = getMailChimpParams(listing);

  const imgAlt = title + ' ' + (display || '') + (' ' + (username || ''));
  const hasVariants = isArrayLength(publicData?.sizes);

  const isRentalPage = publicData?.isRental || isRentalSearchPage();

  return (
    <div
      className={classes}
      onClick={() =>
        typeof window !== 'undefined' && window.sessionStorage.setItem('saveScroll', 'true')
      }
    >
      <div className={css.cardTopInfo}>
        <div className={css.cardTopInfoLeft}>
          <div className={css.authorBlock}>
            {showAuthorInfo ? (
              <>
                <div className={css.authorImg}>
                  {author && author.id && (
                    <AvatarSmall user={author} disableProfileLink={disableProfileLink} />
                  )}
                </div>
                <div className={css.authorInfo}>
                  <FormattedMessage
                    id={isRentalPage ? 'ListingPage.rentWith' : 'ListingPage.soldBy'}
                    values={{ name: authorLink }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        {!isOwnListing ? (
          <div className={css.favorite}>
            <span
              className={css.bookmark}
              onClick={e => {
                if (isAuthenticated) {
                  e.preventDefault();
                  e.stopPropagation();
                  if (id) {
                    const index = bookmarks && bookmarks.findIndex(b => b == id);
                    if (index > -1) {
                      bookmarks && bookmarks.splice(index, 1);
                      setCartItems(bookmarks);
                      onUpdateCurrentUser(bookmarks, false, index);
                      dispatch(
                        removeStoreProductMailChimp({ productId: id, currentUser, isSyncedWithMC })
                      );
                    } else {
                      bookmarks.push(id);
                      setCartItems(bookmarks);
                      onUpdateCurrentUser(Array.from(new Set(bookmarks)), false, index);
                      dispatch(
                        addStoreProductMailChimp({ productParams, currentUser, isSyncedWithMC })
                      );
                    }
                  }
                } else {
                  const localIndex = localBookmarks && localBookmarks.findIndex(b => b == id);
                  if (localIndex > -1) {
                    localBookmarks && localBookmarks.splice(localIndex, 1);
                    const removedBookmarks = Array.from(new Set(localBookmarks));
                    isWindowDefined() &&
                      window.localStorage.setItem(
                        'localBookmarks',
                        JSON.stringify(removedBookmarks)
                      );
                    setCartItems(removedBookmarks);
                  } else {
                    localBookmarks.push(id);
                    const addedBookmarks = Array.from(new Set(localBookmarks));
                    isWindowDefined() &&
                      window.localStorage.setItem('localBookmarks', JSON.stringify(addedBookmarks));
                    setCartItems(addedBookmarks);
                  }
                }
              }}
            >
              {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
                <IconBagFilled />
              ) : (
                <IconBag />
              )}
            </span>
          </div>
        ) : null}
      </div>
      <div className={css.cardImg}>
        {/* <div className={css.authorBlock}>${productPrice && productPrice.toFixed(2)}</div> */}
        {images && images.length ? (
          <ImageSlider
            images={images}
            isMobile={isMobile}
            imgAlt={imgAlt}
            pathParams={{ id, slug }}
            pushToListingPage={() => {
              history.push(
                createResourceLocatorString('ListingPage', routeConfiguration(), { id, slug }, {})
              );
              onUpdateRecentlyViewed();
            }}
          />
        ) : null}
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>${productPrice && productPrice.toFixed(2)}</div>
          <div className={css.price}>
            {!isOwnListing ? (
              <div className={css.saves}>
                <span
                  className={css.bookmark}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!isAuthenticated && e && id) {
                      const routes = routeConfiguration();

                      setLocalStorageItems([id], 'wishlist');
                      setLocalStorageItems(likes, 'currentUserLikes');

                      history.push(createResourceLocatorString('LoginPage', routes, {}, {}), {
                        from: `/favourites`,
                      });
                    } else {
                      const index = wishlist && wishlist.findIndex(b => b == id);
                      const likesIndex =
                        Array.isArray(likes) && likes.findIndex(l => l == currentUser?.id?.uuid);

                      if (id) {
                        if (likesIndex > -1) {
                          likes && likes.splice(likesIndex, 1);
                          const filteredLikes = Array.from(new Set(likes));
                          // To save in listing to show likes
                          setCacheCount(filteredLikes?.length);
                          dispatch(updateSave({ likes: filteredLikes, listingId: id }));

                          // To save in current user as favs
                          wishlist && wishlist.splice(index, 1);
                          const removedwishlist = Array.from(new Set(wishlist));

                          onUpdateCurrentUser(removedwishlist, true, index);
                        } else {
                          Array.isArray(likes) && likes.push(currentUser?.id?.uuid);
                          const filteredLikes = Array.from(new Set(likes));

                          // To save in listing to show likes
                          setCacheCount(filteredLikes?.length);
                          dispatch(updateSave({ likes: filteredLikes, listingId: id }));

                          // To save in current user as favs
                          wishlist.push(id);
                          onUpdateCurrentUser(Array.from(new Set(wishlist)), true, index);
                        }
                      }
                    }
                  }}
                >
                  {likes && likes.findIndex(e => e == currentUser?.id?.uuid) > -1 ? (
                    <IconHeartFilled />
                  ) : (
                    <IconHeart />
                  )}{' '}
                  {cacheCount || likesCount}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
        <div className={css.cardInfo}>
          <div className={css.authorInfo}>
            {brandText}{' '}
            {publicData &&
              publicData.brand &&
              getBrandName(
                publicData.brand,
                config.custom.brandOptions.concat(config.custom.shoesBrandOptions)
              )}
          </div>
        </div>
        <div className={css.cardInfo}>
          <div className={css.authorInfo}>
            {conditionText} {publicData && publicData.condition}
          </div>
        </div>
        <div className={css.authorInfo}>
          {sizeText} {hasVariants ? 'Mulitple sizes available' : publicData?.size}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  setActiveListing: null,
  showAuthorInfo: true,
  brandText: 'Brand:',
  conditionText: 'Condition:',
  sizeText: 'Size:',
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  showAuthorInfo: bool,
  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);

import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { isStockInUse } from '../../util/search';
import { queryTransaction, queryUser } from '../../util/api';
import { getWishlist, isArrayLength } from '../../util/dataExtractors';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const SOLD_ITEM_REQUEST = 'app/ProfilePage/SOLD_ITEM_REQUEST';
export const SOLD_ITEM_SUCCESS = 'app/ProfilePage/SOLD_ITEM_SUCCESS';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';
export const SEARCH_LISTINGS_CUSTOM_SUCCESS = 'app/ProfilePage/SEARCH_LISTINGS_CUSTOM_SUCCESS';
export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const SHOW_LISTING_SUCCESS = 'app/ProfilePage/SHOW_LISTING_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  queryInProgress: false,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  customPageResultIds: [],
  soldItems: 0,
  reviewCount: 0,
  soldListings: [],
  likes: [],
  fetchingSoldInProgress: false,
};

const resultIds = data => data?.data?.map(l => l?.id);
let listings = [];

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      listings = [];
      return { ...initialState };
    case SOLD_ITEM_REQUEST:
      return { ...state, fetchingSoldInProgress: true, soldItems: 0, soldListings: [], likes: [] };
    case SOLD_ITEM_SUCCESS:
      return {
        ...state,
        soldItems: payload?.totalItems,
        soldListings: payload?.soldListings?.map(item => item?.id) || [],
        fetchingSoldInProgress: false,
      };
    case SHOW_LISTING_SUCCESS:
      return { ...state, likes: resultIds(payload?.data) };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };
    case SEARCH_LISTINGS_CUSTOM_SUCCESS:
      listings.push(payload.data.data);
      const mergedListings = [].concat.apply([], listings);
      const ids = mergedListings && mergedListings.map(l => l.id);

      let uniqueArr =
        ids &&
        ids.length &&
        ids.reduce((unique, o) => {
          if (!unique.some(obj => obj['_sdkType'] === o['_sdkType'] && obj.uuid === o.uuid)) {
            unique.push(o);
          }
          return unique;
        }, []);

      return {
        ...state,
        customPageResultIds: uniqueArr,
        searchInProgress: false,
      };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, queryInProgress: false };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload.reviews, reviewCount: payload.reviewCount };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showListingSuccess = response => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { data: response.data },
});

export const soldItemRequest = () => ({
  type: SOLD_ITEM_REQUEST,
});

export const soldItemSuccess = params => ({
  type: SOLD_ITEM_SUCCESS,
  payload: params,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const searchListingsCustomSuccess = response => ({
  type: SEARCH_LISTINGS_CUSTOM_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = payload => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const minStockMaybe = isStockInUse(config) ? { minStock: 0 } : {};

  const extraParams = {
    author_id: userId,
    include: ['author', 'images', 'currentStock'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };

  return sdk.listings
    .query({
      ...minStockMaybe,
      ...extraParams,
    })
    .then(async response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));

      dispatch(addMarketplaceEntities(response));

      const queryNextListings = async (page, totalPages) => {
        const newPage = page + 1;
        const res = await sdk.listings.query({ page: newPage, ...extraParams });
        dispatch(addMarketplaceEntities(res));
        dispatch(searchListingsCustomSuccess(res));

        const meta = resp && resp.data && resp.data.meta;
        const { totalItems = 0 } = meta;

        if (totalItems > 0 && totalPages !== newPage) {
          queryNextListings(newPage, totalPages);
        }
      };

      const resp = await sdk.listings.query({ ...extraParams });
      dispatch(addMarketplaceEntities(resp));

      dispatch(searchListingsCustomSuccess(resp));
      const meta = resp && resp.data && resp.data.meta;
      const page = meta && meta.page;
      const { totalPages = 0, totalItems = 0 } = meta;

      if (totalItems > 0 && totalPages !== page) {
        await queryNextListings(page, totalPages);
      }
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })

    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(
        queryReviewsSuccess({ reviews, reviewCount: response?.data?.meta?.totalItems || 0 })
      );
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const getLikes = likes => async (dispatch, getState, sdk) => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const extraParams = {
    include: ['author', 'images'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 2,
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };

  if (isArrayLength(likes)) {
    Object.assign(extraParams, {
      ids: likes.slice(0, 99),
    });
  }

  const response = await sdk.listings.query({
    ...extraParams,
  });
  dispatch(addMarketplaceEntities(response));
  // const listings = response && response.data && response.data.data;
  dispatch(showListingSuccess(response));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage', 'profile.bio', 'profile.displayName', 'profile.publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const profileUser = response?.data?.data;
      const likes = getWishlist(profileUser);
      if (isArrayLength(likes)) {
        dispatch(getLikes(likes));
      }
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const soldItem = userId => async (dispatch, getState, sdk) => {
  dispatch(soldItemRequest());

  try {
    const { soldListings = [], totalSold = 0 } =
      userId && userId.uuid && (await queryTransaction({ userId: userId.uuid }));

    const listings =
      isArrayLength(soldListings) &&
      soldListings
        .map(item => {
          dispatch(addMarketplaceEntities(item));
          return item?.data?.data;
        })
        ?.flat();
    dispatch(
      soldItemSuccess({
        totalItems: totalSold || 0,
        soldListings: listings || [],
      })
    );
  } catch (error) {
    console.log(`error while fetching sold items for user ${userId?.uuid}`);
  }
};

export const setLoadData = (profileId, username) => async (dispatch, getState, sdk) => {
  const queryId = !profileId && username && (await queryUser({ username }));
  const userId = (queryId || profileId) && new UUID(queryId || profileId);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());
  if (userId) {
    return Promise.all([
      dispatch(fetchCurrentUser()),
      // dispatch(soldItem(userId)),
      dispatch(showUser(userId)),
      dispatch(queryUserListings(userId)),
      dispatch(queryUserReviews(userId)),
    ]);
  }
};

export const loadData = (params, search, state) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id || state?.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    // dispatch(soldItem(userId)),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
  ]);
};

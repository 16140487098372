import React, { useState } from 'react';
import IconCustomer from '../../components/IconCustomer/IconCustomer';
import IconShop from '../../components/IconShop/IconShop';

import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration, { draftId, draftSlug } from '../../routing/routeConfiguration';

import css from './DashboardSidebar.module.css';
import Icons from '../Icons/Icons';
import {
  selectCurrentUser,
  selectCurrentUserHasListings,
  selectCurrentUserHasNotifications,
} from '../../ducks/user.duck';
import { getUsername } from '../../util/dataExtractors';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ACCOUNT_PAGE, STRING } from '../../util/enums';

const SideLink = ({ iconName, linkText, onClick, isOffers = null }) => {
  return (
    <div className={css.sidelink} onClick={onClick}>
      <span className={classNames(css.sideNavIcon, css.shopIcon)}>
        <Icons name={iconName} />
      </span>{' '}
      {linkText}
      {/* {isOffers} */}
    </div>
  );
};

function DashboardSidebar() {
  const currentUser = useSelector(selectCurrentUser);
  const currentUserListings = useSelector(selectCurrentUserHasListings);
  const userNotifications = useSelector(selectCurrentUserHasNotifications);
  const history = useHistory();
  const location = useLocation();
  const username = getUsername(currentUser);
  const pathname = location && location.pathname;

  const isAccountPage =
    typeof pathname === STRING && pathname ? location.pathname.includes(ACCOUNT_PAGE) : false;

  const [toggle, setToggle] = useState({ account: isAccountPage ? isAccountPage : false });

  const { account = false } = toggle || {};

  const redirectToProfilePage = () => {
    const state = { state: currentUser?.id?.uuid };
    const params = username ? { username } : { id: currentUser?.id?.uuid };

    history.push(createResourceLocatorString('StorePage', routeConfiguration(), params, {}), state);
  };

  const redirectToPage = (name, params = {}) => {
    history.push(createResourceLocatorString(name, routeConfiguration(), params, {}));
  };

  const onToggle = type => {
    const selected = toggle[type];
    setToggle({ ...toggle, [type]: !selected });
  };

  const notificationDot =
    userNotifications > 0 ? <div className={css.notificationDot}>{userNotifications}</div> : null;

  return (
    <div>
      <div className={css.accordionMenu}>
        <SideLink
          iconName="dashboardHome"
          linkText="Home"
          onClick={() => redirectToPage('LandingPage')}
        />
        <SideLink
          iconName="dashboardPayouts"
          linkText="Payouts"
          onClick={() => redirectToPage('DashboardPage')}
        />
        <div
          className={classNames(css.sidelink, {
            [css.disabled]: !currentUserListings,
          })}
          onClick={!currentUserListings ? () => {} : redirectToProfilePage}
        >
          <span className={classNames(css.sideNavIcon, css.shopIcon)}>
            <IconShop />
          </span>{' '}
          Shop
        </div>
        <SideLink
          iconName="dashboardInbox"
          linkText="Orders"
          onClick={() => redirectToPage('InboxPage', { tab: 'all' })}
        />
        <SideLink
          iconName="listings"
          linkText="Your listings"
          onClick={() => redirectToPage('ManageListingsPage')}
        />
        {/* <SideLink
          iconName="rentals"
          linkText="Rentals"
          onClick={() =>
            redirectToPage('EditListingPage', {
              slug: draftSlug,
              id: draftId,
              type: 'new',
              tab: 'rental',
            })
          }
        /> */}
        <SideLink
          iconName="rentals"
          linkText="Rentals"
          onClick={() =>
            redirectToPage('EditListingPage', {
              slug: draftSlug,
              id: draftId,
              type: 'new',
              tab: 'rental',
            })
          }
        />
        <SideLink
          isOffers={notificationDot}
          iconName="offerIcon"
          linkText="Offers"
          onClick={() => redirectToPage('OffersPage')}
        />
        <SideLink
          iconName="profile"
          linkText=" Profile Settings"
          onClick={() => redirectToPage('ProfileSettingsPage')}
        />
        <div className={css.sidelink}>
          <div className={css.sideDropdown} onClick={() => onToggle('account')}>
            <span>
              {' '}
              <span className={classNames(css.sideNavIcon, css.shopIcon)}>
                <IconCustomer />
              </span>{' '}
              Account
            </span>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name={account ? 'upArrow' : 'downArrow'} />
            </span>
          </div>
        </div>
        {account ? (
          <div className={css.sideChildLinks}>
            <SideLink
              iconName="contact"
              linkText="Contact details"
              onClick={() => redirectToPage('ContactDetailsPage')}
            />
            <SideLink
              iconName="shipping"
              linkText="Shipping details"
              onClick={() => redirectToPage('ShippingDetailsPage')}
            />
            <SideLink
              iconName="password"
              linkText="Password"
              onClick={() => redirectToPage('PasswordChangePage')}
            />
            <SideLink
              iconName="payout"
              linkText="Payout details"
              onClick={() => redirectToPage('StripePayoutPage')}
            />
            <SideLink
              iconName="paypal"
              linkText="Paypal account"
              onClick={() => redirectToPage('PayPalMerchantPage')}
            />
            <SideLink
              iconName="card"
              linkText="Payment methods"
              onClick={() => redirectToPage('PaymentMethodsPage')}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DashboardSidebar;

import React from 'react';
import leotards from '../assets/shopByCatergory/leotards.webp';
import shoes from '../assets/shopByCatergory/shoes.webp';
import warmups from '../assets/shopByCatergory/warmups.webp';
import shopAll from '../assets/shopByCatergory/shopAll.webp';

import luckyleo from '../assets/shopByBrand/luckyleo.webp';
import bloch from '../assets/shopByBrand/bloch.webp';
import mariia from '../assets/shopByBrand/mariia.webp';
import yumiko from '../assets/shopByBrand/yumiko.webp';
import eleve from '../assets/shopByBrand/eleve.webp';
import wearmoi from '../assets/shopByBrand/wearmoi.webp';
import capezio from '../assets/shopByBrand/capezio.webp';
import ainsliewear from '../assets/shopByBrand/ainsliewear.webp';

import blackIcon from '../assets/colors/Black.png';
import blueIcon from '../assets/colors/Blue.png';
import brownIcon from '../assets/colors/Brown.png';
import goldIcon from '../assets/colors/Gold.png';
import greenIcon from '../assets/colors/Green.png';
import greyIcon from '../assets/colors/Grey.png';
import multiColorIcon from '../assets/colors/MultiColor.png';
import orangeIcon from '../assets/colors/Orange.png';
import pinkIcon from '../assets/colors/Pink.png';
import purpleIcon from '../assets/colors/Purple.png';
import redIcon from '../assets/colors/Red.png';
import silverIcon from '../assets/colors/Silver.png';
import skinTonesIcon from '../assets/colors/SkinTones.png';
import whiteIcon from '../assets/colors/White.png';
import yellowIcon from '../assets/colors/Yellow.png';

import dancewearIcon from '../assets/filterIcons/dancewear.png';
import shoesIcon from '../assets/filterIcons/shoes.png';
// import costumesIcon from '../assets/filterIcons/costume.png';
// import newIcon from '../assets/filterIcons/new.png';
// import shippingIcon from '../assets/filterIcons/shipping.png';
// import freeShippingIcon from '../assets/filterIcons/freeShipping.png';
// import leotardIcon from '../assets/filterIcons/leotard.png';
// import skirtIcon from '../assets/filterIcons/skirt.png';
// import blueIcon from '../assets/filterIcons/blue.png';
// import blackIcon from '../assets/filterIcons/black.png';
// import pinkIcon from '../assets/filterIcons/pink.png';
// import purpleIcon from '../assets/filterIcons/purple.png';
// import IconDress from '../components/IconDress/IconDress';
// import IconShoes from '../components/IconShoes/IconShoes';
// import IconLeotard from '../components/IconLeotard/IconLeotard';
import IconNew from '../components/IconNew/IconNew';
import IconFreeShipping from '../components/IconFreeShipping/IconFreeShipping';
// import IconShipping from '../components/IconShipping/IconShipping';
// import IconPink from '../components/IconPink/IconPink';
// import IconPurple from '../components/IconPurple/IconPurple';
// import IconBlue from '../components/IconBlue/IconBlue';
// import IconBlack from '../components/IconBlack/IconBlack';
import { profileFilter } from './enums';
// import IconTutulist from '../components/IconTutulist/IconTutulist';
import logo from '../assets/logo-small.png';

import medium from '../assets/filterIcons/medium.png';
import s from '../assets/filterIcons/small.png';
import xl from '../assets/filterIcons/xl.jpg';
import xs from '../assets/filterIcons/xs.jpg';
import large from '../assets/filterIcons/large.png';
import paypal from '../assets/filterIcons/paypal.png';

import balletIcon from '../assets/costumes/ballet.png';
import customIcon from '../assets/costumes/custom.png';
import lotsIcon from '../assets/costumes/lots.png';
import catalogueIcon from '../assets/costumes/catalogue.png';
import jazzIcon from '../assets/costumes/jazz.png';
import singleIcon from '../assets/costumes/single.png';
import contemporaryIcon from '../assets/costumes/contemporary.png';

/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

function generateNumbers(start, end, step) {
  const numbers = [];
  for (let i = start; i <= end; i += step) {
    numbers.push(i);
  }
  return numbers;
}

const onlyNumbers = generateNumbers(1, 49.5, 0.5);

const numberOptions = onlyNumbers.map(item => ({
  values: item.toString(),
  label: item.toString(),
}));

const lengthOptions = [
  { values: 'XS', label: 'XS' },
  { values: 'S', label: 'S' },
  { values: 'M', label: 'M' },
  { values: 'P', label: 'P' },
  { values: 'L', label: 'L' },
  { values: 'XL', label: 'XL' },
  { values: 'XXL', label: 'XXL' },
  { values: 'XXXL', label: 'XXXL' },
];

// Use spread operator to combine lengthOptions and numberOptions
export const shoesSizesOptions = [...lengthOptions, ...numberOptions];

export const shoesSizesFilters = shoesSizesOptions.map(({ label }) => ({ key: label, label }));

export const filters = [
  {
    id: 'encore',
    label: 'Tutulist Encore',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_tutulistEncore'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'true',
          label: 'True',
          icon: <img src={logo} alt="Tutulist Encore" style={{ width: '24px', height: '24px' }} />,
        },
      ],
    },
  },
  {
    id: profileFilter.SIZE,
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'X-Small', label: 'Adult X-Small (0-2)' },
        { key: 'Small', label: 'Adult Small (4-6)' },
        { key: 'Medium', label: 'Adult Medium (6-8)' },
        { key: 'Large', label: 'Adult Large (10-12)' },
        { key: 'X-Large', label: 'Adult X-Large (14-16)' },
        { key: '2XL', label: 'Adult XXL' },
        { key: 'Child-X-Small', label: 'Child X-Small (2-4)' },
        { key: 'Child-Small', label: 'Child Small (4-6)' },
        { key: 'Child-Medium', label: 'Child Medium (6-8)' },
        { key: 'Child-Large', label: 'Child Large (10-12)' },
        { key: 'Child-X-Large', label: 'Child X-Large (12-14)' },
        { key: 'Intermediate-Child', label: 'Intermediate Child' },
        { key: 'One-Size', label: 'One Size' },
        ...shoesSizesFilters,
      ],
    },
  },
  {
    id: profileFilter.BRAND,
    label: 'Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1737', label: '1737' },
        { key: 'AA-Dance-Costumes', label: 'AA Dance Costumes' },
        { key: 'A-Wish-Come-True', label: 'A Wish Come True' },
        { key: 'ABT', label: 'ABT' },
        { key: 'Adidas', label: 'Adidas' },
        { key: 'Ainsliewear', label: 'Ainsliewear' },
        { key: 'Algy', label: 'Algy' },
        { key: 'AK-Dancewear', label: 'AK Dancewear' },
        { key: 'Ashley-Boulder', label: 'Ashley Boulder' },
        { key: 'Attitude-Diffusion', label: 'Attitude Diffusion' },
        { key: 'Athleta', label: 'Athleta' },
        { key: 'Audition-Dance', label: 'Audition Dance' },
        { key: 'Balera', label: 'Balera' },
        { key: 'Ballet-Rosa', label: 'Ballet Rosa' },
        { key: 'Baltogs', label: 'Baltogs' },
        { key: 'Barbara-Ellen', label: 'Barbara Ellen' },
        { key: 'Billiejo', label: 'Billiejo' },
        { key: 'Bloch', label: 'Bloch' },
        { key: 'Bodile', label: 'Bodile' },
        { key: 'Bodywrappers', label: 'Bodywrappers' },
        { key: 'Bosaddo', label: 'Bosaddo' },
        { key: 'Boutique', label: 'Boutique' },
        { key: 'Unknown', label: 'Unknown' },
        { key: 'Bullet-Pointe', label: 'Bullet Pointe' },
        { key: 'California-Kisses', label: 'California Kisses' },
        { key: 'Calla', label: 'Calla' },
        { key: 'Capezio', label: 'Capezio' },
        { key: 'Chameleon', label: 'Chameleon' },
        { key: 'Class-In', label: 'Class In' },
        { key: 'Clementine', label: 'Clementine' },
        { key: 'Cleo', label: 'Cleo' },
        { key: 'Cleo-Harper', label: 'Cleo Harper' },
        { key: 'Cloud-Victory', label: 'Cloud & Victory' },
        { key: 'Collections-by-Claudia', label: 'Collections by Claudia' },
        { key: 'Creations-By-Cici', label: 'Creations By Cici' },
        { key: 'Curtain-Call', label: 'Curtain Call' },
        { key: 'Custom', label: 'Custom' },
        { key: 'DA-Designs', label: 'DA Designs' },
        { key: 'Danse-De-Paris', label: 'Danse De Paris' },
        { key: 'Dansco', label: 'Dansco' },
        { key: 'Danskin', label: 'Danskin' },
        { key: 'Danzbaby', label: 'Danzbaby' },
        { key: 'Danz-N-Motion', label: 'Danz N Motion' },
        { key: 'DayDance', label: 'DayDance' },
        { key: 'Double-Platinum', label: 'Double Platinum' },
        { key: 'Ds-Dancewear', label: 'Ds Dancewear' },
        { key: 'Eleve', label: 'Eleve' },
        { key: 'Eurotard', label: 'Eurotard' },
        { key: 'First-Position', label: 'First Position' },
        { key: 'Five-Dancewear', label: 'Five Dancewear' },
        { key: 'Freed', label: 'Freed' },
        { key: 'Funkeys', label: 'Funkeys' },
        { key: 'Gaynor-Minden', label: 'Gaynor Minden' },
        { key: 'Gia-Mia', label: 'Gia Mia' },
        { key: 'Gilda-Marx', label: 'Gilda Marx' },
        { key: 'Grishko', label: 'Grishko' },
        { key: 'Harmonie', label: 'Harmonie' },
        { key: 'Honeycut', label: 'Honeycut' },
        { key: 'Imperfect-Pointes', label: 'Imperfect Pointes' },
        { key: 'Ingenne', label: 'Ingenne' },
        { key: 'Ivivva', label: 'Ivivva' },
        { key: 'Jo-Jax', label: 'Jo + Jax' },
        { key: 'Justice', label: 'Justice' },
        { key: 'Jule-Dancewear', label: 'Jule Dancewear' },
        { key: 'Just-a-Corpse', label: 'Just a Corpse' },
        { key: 'K-H-Martin', label: 'K.H. Martin' },
        { key: 'Kandi-Kouture', label: 'Kandi Kouture' },
        { key: 'Kelle', label: 'Kelle' },
        { key: 'KTRNA', label: 'KTRNA' },
        { key: 'LA-Dance-Designs', label: 'LA Dance Designs' },
        { key: 'LeaMarie-Dancewear', label: 'LeaMarie Dancewear' },
        { key: 'Leos-Dancewear', label: "Leo's Dancewear" },
        { key: 'Lignes', label: 'Lignes' },
        { key: 'Little-Stars', label: 'Little Stars' },
        { key: 'Lola-Stretch', label: 'Lola Stretch' },
        { key: 'LuckyLeo', label: 'LuckyLeo' },
        { key: 'Luckyleo-Dancewear', label: 'Luckyleo Dancewear' },
        { key: 'Lululemon', label: 'Lululemon' },
        { key: 'Lulli', label: 'Lulli' },
        { key: 'Mama-Co', label: 'Mama Co.' },
        { key: 'Mancca-Activewear', label: 'Mancca Activewear' },
        { key: 'Maldire', label: 'Maldire' },
        { key: 'Mariia', label: 'Mariia' },
        { key: 'Merlet', label: 'Merlet' },
        { key: 'Mirella', label: 'Mirella' },
        { key: 'Motionwear', label: 'Motionwear' },
        { key: 'Mstevens', label: 'Mstevens' },
        { key: 'Natalie', label: 'Natalie' },
        { key: 'Natalie-Couture', label: 'Natalie Couture' },
        { key: 'Nike', label: 'Nike' },
        { key: 'Nikolay', label: 'Nikolay' },
        { key: 'Oh-La-La-Dancewear', label: 'Oh La La Dancewear' },
        { key: 'Olly-Designs', label: 'Olly Designs' },
        { key: 'Onstage', label: 'Onstage' },
        { key: 'Other', label: 'Other' },
        { key: 'Opra-Dancewear', label: 'Opra-Dancewear' },
        { key: 'Patrick-J-Design', label: 'Patrick J Design' },
        { key: 'Pillows-for-Pointes', label: 'Pillows for Pointes' },
        { key: 'Plume-Dancewear', label: 'Plume Dancewear' },
        { key: 'Premeire-Collection', label: 'Premeire Collection' },
        { key: 'Purple-Pixies', label: 'Purple Pixies' },
        { key: 'Repetto', label: 'Repetto' },
        { key: 'Reputation', label: 'Reputation' },
        { key: 'Reverence', label: 'Reverence' },
        { key: 'Revolution-Dance', label: 'Revolution Dance' },
        { key: 'Rosette', label: 'Rosette' },
        { key: 'RubiaWear', label: 'RubiaWear' },
        { key: 'Russian-Pointe', label: 'Russian Pointe' },
        { key: 'Sadie-Jane', label: 'Sadie Jane' },
        { key: 'Sansha', label: 'Sansha' },
        { key: 'Sodanca', label: 'Sodanca' },
        { key: 'Suffolk', label: 'Suffolk' },
        { key: 'Sugar-Bruno', label: 'Sugar & Bruno' },
        { key: 'Tags-Cut', label: 'Tags Cut' },
        { key: 'Theatricals', label: 'Theatricals' },
        { key: 'Tiger-Friday', label: 'Tiger Friday' },
        { key: 'Trienawear', label: 'Trienawear' },
        { key: 'Under-Armour', label: 'Under Armour' },
        { key: 'Urban-Groove', label: 'Urban Groove' },
        { key: 'Watercolour-Dancewear', label: 'Watercolour Dancewear' },
        { key: 'Wear-Moi', label: 'Wear Moi' },
        { key: 'Weissman', label: 'Weissman' },
        { key: 'World-Wide-Ballet', label: 'World Wide Ballet' },
        { key: 'Yukitard', label: 'Yukitard' },
        { key: 'Yumiko', label: 'Yumiko' },
        { key: 'Zella', label: 'Zella' },
        { key: 'Other', label: 'Other' },
        { key: 'Angelo-Luzio', label: 'Angelo Luzio' },
        { key: 'Bloch', label: 'Bloch' },
        { key: 'Body-Basic', label: 'Body Basic' },
        { key: 'Bunheads', label: 'Bunheads' },
        { key: 'Capezio', label: 'Capezio' },
        { key: 'Chacott', label: 'Chacott' },
        { key: 'DANZMOTION', label: 'DANZMOTION' },
        { key: 'Dance-Class', label: 'Dance Class' },
        { key: 'Dance-Department', label: 'Dance Department' },
        { key: 'Dance-Now', label: 'Dance Now' },
        { key: 'Danztech', label: 'Danztech' },
        { key: 'Discount-Dance-Supple', label: 'Discount Dance Supple' },
        { key: 'Freed', label: 'Freed' },
        { key: 'Fuzi', label: 'Fuzi' },
        { key: 'Gaynor-Minden', label: 'Gaynor Minden' },
        { key: 'Go-Go', label: 'Go Go' },
        { key: 'Kryolan', label: 'Kryolan' },
        { key: 'Leos', label: 'Leos' },
        { key: 'Mariia-MX', label: 'Mariia MX' },
        { key: 'Mariia-Skye', label: 'Mariia Skye' },
        { key: 'Mirella', label: 'Mirella' },
        { key: 'Natalie', label: 'Natalie' },
        { key: 'Nikolay', label: 'Nikolay' },
        { key: 'Pastry', label: 'Pastry' },
        { key: 'Pillows-for-Pointes', label: 'Pillows for Pointes' },
        { key: 'Prima-Soft', label: 'Prima Soft' },
        { key: 'Russian-Pointe', label: 'Russian Pointe' },
        { key: 'Sansha', label: 'Sansha' },
        { key: 'Stephanie', label: 'Stephanie' },
        { key: 'Suffolk', label: 'Suffolk' },
        { key: 'Sure-Foot', label: 'Sure Foot' },
        { key: 'Sweet-Feet', label: 'Sweet Feet' },
        { key: 'Theatricals', label: 'Theatricals' },
        { key: 'Theatricals-For-Every-Stage', label: 'Theatricals For Every Stage' },
        { key: 'Toe-Saver', label: 'Toe Saver' },
        { key: 'Very-Fine-Shoes', label: 'Very Fine Shoes' },
        { key: 'Wear-Moi', label: 'Wear Moi' },
      ],
    },
  },
  {
    id: profileFilter.COLOR,
    label: 'Color',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Black', label: 'Black', icon: blackIcon },
        { key: 'Blue', label: 'Blue', icon: blueIcon },
        { key: 'Brown', label: 'Brown', icon: brownIcon },
        { key: 'Gold', label: 'Gold', icon: goldIcon },
        { key: 'Gray', label: 'Gray', icon: greyIcon },
        { key: 'Green', label: 'Green', icon: greenIcon },
        { key: 'Orange', label: 'Orange', icon: orangeIcon },
        { key: 'Pink', label: 'Pink', icon: pinkIcon },
        { key: 'Purple', label: 'Purple', icon: purpleIcon },
        { key: 'Multi-Colored', label: 'Multi Colored', icon: multiColorIcon },
        { key: 'Red', label: 'Red', icon: redIcon },
        { key: 'Silver', label: 'Silver', icon: silverIcon },
        { key: 'SkinTones', label: 'Skin Tones', icon: skinTonesIcon },
        { key: 'White', label: 'White', icon: whiteIcon },
        { key: 'Yellow', label: 'Yellow', icon: yellowIcon },
      ],
    },
  },

  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: profileFilter.CATEGORY,
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'dancewear', label: 'Dancewear', icon: dancewearIcon },
        { key: 'shoes', label: 'Shoes', icon: shoesIcon },
        // { key: 'costumes', label: 'Costumes', icon: costumesIcon },
      ],
    },
  },
  {
    id: profileFilter.CONDITION,
    label: 'Condition',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Fair', label: 'Fair' },
        { key: 'Good', label: 'Good' },
        { key: 'Like-New', label: 'Like New' },
        { key: 'New', label: 'New' },
        // { key: 'Donate', label: 'Donate' },
        // { key: 'Needs-Work', label: 'Needs Work' },
      ],
    },
  },
  {
    id: profileFilter.TYPE,
    label: 'Type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Leotard', label: 'Leotard' },
        { key: 'Warmups', label: 'Warmups' },
        { key: 'Contemporary', label: 'Contemporary Sets' },
        // { key: 'Skirts', label: 'Skirts' },
        { key: 'Unitard/Biketard', label: 'Unitard/Biketard' },
        { key: 'Tops', label: 'Tops' },
        { key: 'Bottoms', label: 'Bottoms' },
        { key: 'Skirt', label: 'Skirts' },
        { key: 'Tights', label: 'Tights' },
        { key: 'Accessories', label: 'Accessories' },
        { key: 'Undergarments', label: 'Undergarments' },
        { key: 'tap', label: 'Tap' },
        { key: 'pointe', label: 'Pointe' },
        { key: 'technique', label: 'Technique' },
        { key: 'character', label: 'Character' },
        { key: 'ballet', label: 'Ballet' },
        { key: 'ballroom', label: 'Ballroom' },
        { key: 'cheer-Team', label: 'Cheer Team' },
        { key: 'fitness', label: 'Fitness' },
        { key: 'gymnastics', label: 'Gymnastics' },
        { key: 'hip-hop', label: 'Hip Hop' },
        { key: 'jazz', label: 'Jazz' },
        { key: 'lyrical', label: 'Lyrical' },
        { key: 'modern', label: 'Modern' },
        { key: 'other', label: 'Other' },
        { key: 'praise', label: 'Praise' },
      ],
    },
  },
  {
    id: profileFilter.SHANK,
    label: 'Shank',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_shank'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'F', label: 'F' },
        { key: 'FX', label: 'FX' },
        { key: 'Flexible-Medium', label: 'Flexible Medium' },
        { key: 'Flexible-Soft', label: 'Flexible Soft' },
        { key: 'Full-Normal', label: 'Full Normal' },
        { key: 'Full-Reinforced', label: 'Full Reinforced' },
        { key: 'Full-Soft', label: 'Full Soft' },
        { key: 'H', label: 'H' },
        { key: 'HD', label: 'HD' },
        { key: 'Hard', label: 'Hard' },
        { key: 'Light', label: 'Light' },
        { key: 'Medium', label: 'Medium' },
        { key: 'P', label: 'P' },
        { key: 'Regular', label: 'Regular' },
        { key: 'S', label: 'S' },
        { key: 'SF', label: 'SF' },
        { key: 'Soft', label: 'Soft' },
        { key: 'Standard', label: 'Standard' },
        { key: 'X', label: 'X' },
      ],
    },
  },
  {
    id: profileFilter.WIDTH,
    label: 'Width',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_width'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '0x', label: '0x' },
        { key: '1X', label: '1X' },
        { key: '2X', label: '2X' },
        { key: '3X', label: '3X' },
        { key: '4X', label: '4X' },
        { key: 'A', label: 'A' },
        { key: 'B', label: 'B' },
        { key: 'C', label: 'C' },
        { key: 'D', label: 'D' },
        { key: 'E', label: 'E' },
        { key: 'EE', label: 'EE' },
        { key: 'M', label: 'M' },
        { key: 'N', label: 'N' },
        { key: 'OX', label: 'OX' },
        { key: 'W', label: 'W' },
        { key: 'W2', label: 'W2' },
        { key: 'W3', label: 'W3' },
        { key: 'W4', label: 'W4' },
        { key: 'W5', label: 'W5' },
        { key: 'W6', label: 'W2' },
        { key: 'WW', label: 'WW' },
        { key: 'X', label: 'X' },
        { key: 'X0', label: 'X0' },
        { key: 'X1', label: 'X1' },
        { key: 'X2', label: 'X2' },
        { key: 'X3', label: 'X3' },
        { key: 'X4', label: 'X4' },
        { key: 'X5', label: 'X5' },
        { key: 'X6', label: 'X6' },
        { key: 'XN', label: 'XN' },
        { key: 'XX', label: 'XX' },
        { key: 'XXN', label: 'XXN' },
        { key: 'XXX', label: 'XXX' },
        { key: 'XXXN', label: 'XXXN' },
      ],
    },
  },

  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: [profileFilter.SIZE, profileFilter.BRAND, profileFilter.CATEGORY],
};

export const categories = [
  { key: profileFilter.DANCEWEAR, label: 'Dancewear' },
  { key: profileFilter.SHOES, label: 'Shoes' },
];

export const brandOptions = [
  { values: '1737', label: '1737' },
  { values: 'AA-Dance-Costumes', label: 'AA Dance Costumes' },
  { values: 'A-Wish-Come-True', label: 'A Wish Come True' },
  { values: 'ABT', label: 'ABT' },
  { values: 'Adidas', label: 'Adidas' },
  { values: 'AK-Dancewear', label: 'AK Dancewear' },
  { values: 'Ainsliewear', label: 'Ainsliewear' },
  { values: 'Algy', label: 'Algy' },
  { values: 'Ashley-Boulder', label: 'Ashley Boulder' },
  { values: 'Attitude-Diffusion', label: 'Attitude Diffusion' },
  { values: 'Athleta', label: 'Athleta' },
  { values: 'Audition-Dance', label: 'Audition Dance' },
  { values: 'Balera', label: 'Balera' },
  { values: 'Ballet-Rosa', label: 'Ballet Rosa' },
  { values: 'Baltogs', label: 'Baltogs' },
  { values: 'Barbara-Ellen', label: 'Barbara Ellen' },
  { values: 'Billiejo', label: 'Billiejo' },
  { values: 'Bloch', label: 'Bloch' },
  { values: 'Bodywrappers', label: 'Bodywrappers' },
  { values: 'Bodile', label: 'Bodile' },
  { values: 'Bosaddo', label: 'Bosaddo' },
  { values: 'Boutique', label: 'Boutique' },
  { values: 'Bullet-Pointe', label: 'Bullet Pointe' },
  { values: 'California-Kisses', label: 'California Kisses' },
  { values: 'Calla', label: 'Calla' },
  { values: 'Capezio', label: 'Capezio' },
  { values: 'Chameleon', label: 'Chameleon' },
  { values: 'Class-In', label: 'Class In' },
  { values: 'Clementine', label: 'Clementine' },
  { values: 'Cleo', label: 'Cleo' },
  { values: 'Cleo-Harper', label: 'Cleo Harper' },
  { values: 'Cloud-Victory', label: 'Cloud & Victory' },
  { values: 'Collections-by-Claudia', label: 'Collections by Claudia' },
  { values: 'Creations-By-Cici', label: 'Creations By Cici' },
  { values: 'Curtain-Call', label: 'Curtain Call' },
  { values: 'Custom', label: 'Custom' },
  { values: 'DA-Designs', label: 'DA Designs' },
  { values: 'Danse-De-Paris', label: 'Danse De Paris' },
  { values: 'Dansco', label: 'Dansco' },
  { values: 'Danskin', label: 'Danskin' },
  { values: 'Danzbaby', label: 'Danzbaby' },
  { values: 'Danz-N-Motion', label: 'Danz N Motion' },
  { values: 'Double-Platinum', label: 'Double Platinum' },
  { values: 'DayDance', label: 'DayDance' },
  { values: 'Ds-Dancewear', label: 'Ds Dancewear' },
  { values: 'Eleve', label: 'Eleve' },
  { values: 'Eurotard', label: 'Eurotard' },
  { values: 'First-Position', label: 'First Position' },
  { values: 'Five-Dancewear', label: 'Five Dancewear' },
  { values: 'Freed', label: 'Freed' },
  { values: 'Funkeys', label: 'Funkeys' },
  { values: 'Gaynor-Minden', label: 'Gaynor Minden' },
  { values: 'Gia-Mia', label: 'Gia Mia' },
  { values: 'Gilda-Marx', label: 'Gilda Marx' },
  { values: 'Grishko', label: 'Grishko' },
  { values: 'Harmonie', label: 'Harmonie' },
  { values: 'Honeycut', label: 'Honeycut' },
  { values: 'Imperfect-Pointes', label: 'Imperfect Pointes' },
  { values: 'Ingenne', label: 'Ingenne' },
  { values: 'Ivivva', label: 'Ivivva' },
  { values: 'Jo-Jax', label: 'Jo + Jax' },
  { values: 'Justice', label: 'Justice' },
  { values: 'Jule-Dancewear', label: 'Jule Dancewear' },
  { values: 'Just-a-Corpse', label: 'Just a Corpse' },
  { values: 'K-H-Martin', label: 'K.H. Martin' },
  { values: 'Kandi-Kouture', label: 'Kandi Kouture' },
  { values: 'Kelle', label: 'Kelle' },
  { values: 'KTRNA', label: 'KTRNA' },
  { values: 'LA-Dance-Designs', label: 'LA Dance Designs' },
  { values: 'LeaMarie-Dancewear', label: 'LeaMarie Dancewear' },
  { values: 'Leos-Dancewear', label: "Leo's Dancewear" },
  { values: 'Lignes', label: 'Lignes' },
  { values: 'Little-Stars', label: 'Little Stars' },
  { values: 'Lola-Stretch', label: 'Lola Stretch' },
  { values: 'Luckyleo-Dancewear', label: 'Luckyleo Dancewear' },
  { values: 'Lululemon', label: 'Lululemon' },
  { values: 'Lulli', label: 'Lulli' },
  { values: 'Mama-Co', label: 'Mama Co.' },
  { values: 'Maldire', label: 'Maldire' },
  { values: 'Mancca-Activewear', label: 'Mancca Activewear' },
  { values: 'Mariia', label: 'Mariia' },
  { values: 'Merlet', label: 'Merlet' },
  { values: 'Mirella', label: 'Mirella' },
  { values: 'Motionwear', label: 'Motionwear' },
  { values: 'Mstevens', label: 'Mstevens' },
  { values: 'Natalie', label: 'Natalie' },
  { values: 'Natalie-Couture', label: 'Natalie Couture' },
  { values: 'Nikolay', label: 'Nikolay' },
  { values: 'Nike', label: 'Nike' },
  { values: 'Oh-La-La-Dancewear', label: 'Oh La La Dancewear' },
  { values: 'Olly-Designs', label: 'Olly Designs' },
  { values: 'Onstage', label: 'Onstage' },
  { values: 'Other', label: 'Other' },
  { values: 'Opra-Dancewear', label: 'Opra-Dancewear' },
  { values: 'Patrick-J-Design', label: 'Patrick J Design' },
  { values: 'Pillows-for-Pointes', label: 'Pillows for Pointes' },
  { values: 'Plume-Dancewear', label: 'Plume Dancewear' },
  { values: 'Premeire-Collection', label: 'Premeire Collection' },
  { values: 'Purple-Pixies', label: 'Purple Pixies' },
  { values: 'Repetto', label: 'Repetto' },
  { values: 'Reputation', label: 'Reputation' },
  { values: 'Reverence', label: 'Reverence' },
  { values: 'Revolution-Dance', label: 'Revolution Dance' },
  { values: 'Rosette', label: 'Rosette' },
  { values: 'RubiaWear', label: 'RubiaWear' },
  { values: 'Russian-Pointe', label: 'Russian Pointe' },
  { values: 'Sadie-Jane', label: 'Sadie Jane' },
  { values: 'Sansha', label: 'Sansha' },
  { values: 'Sodanca', label: 'Sodanca' },
  { values: 'Suffolk', label: 'Suffolk' },
  { values: 'Sugar-Bruno', label: 'Sugar & Bruno' },
  { values: 'Theatricals', label: 'Theatricals' },
  { values: 'Tiger-Friday', label: 'Tiger Friday' },
  { values: 'Trienawear', label: 'Trienawear' },
  { values: 'Under-Armour', label: 'Under Armour' },
  { values: 'Unknown', label: 'Unknown' },
  { values: 'Urban-Groove', label: 'Urban Groove' },
  { values: 'Watercolour-Dancewear', label: 'Watercolour Dancewear' },
  { values: 'Wear-Moi', label: 'Wear Moi' },
  { values: 'Weissman', label: 'Weissman' },
  { values: 'World-Wide-Ballet', label: 'World Wide Ballet' },
  { values: 'Yukitard', label: 'Yukitard' },
  { values: 'Yumiko', label: 'Yumiko' },
  { values: 'Zella', label: 'Zella' },
  { values: 'Other', label: 'Other' },
];

export const shoesBrandOptions = [
  { values: 'Angelo-Luzio', label: 'Angelo Luzio' },
  { values: 'Bloch', label: 'Bloch' },
  { values: 'Body-Basic', label: 'Body Basic' },
  { values: 'Bunheads', label: 'Bunheads' },
  { values: 'Capezio', label: 'Capezio' },
  { values: 'Chacott', label: 'Chacott' },
  { values: 'DANZMOTION', label: 'DANZMOTION' },
  { values: 'Dance-Class', label: 'Dance Class' },
  { values: 'Dance-Department', label: 'Dance Department' },
  { values: 'Dance-Now', label: 'Dance Now' },
  { values: 'Danztech', label: 'Danztech' },
  { values: 'Discount-Dance-Supple', label: 'Discount Dance Supple' },
  { values: 'Double-Platinum', label: 'Double Platinum' },
  { values: 'Freed', label: 'Freed' },
  { values: 'Fuzi', label: 'Fuzi' },
  { values: 'Gaynor-Minden', label: 'Gaynor Minden' },
  { values: 'Go-Go', label: 'Go Go' },
  { values: 'Kryolan', label: 'Kryolan' },
  { values: 'Leos', label: 'Leos' },
  { values: 'Mariia-MX', label: 'Mariia MX' },
  { values: 'Mariia-Skye', label: 'Mariia Skye' },
  { values: 'Mirella', label: 'Mirella' },
  { values: 'Natalie', label: 'Natalie' },
  { values: 'Nikolay', label: 'Nikolay' },
  { values: 'Pastry', label: 'Pastry' },
  { values: 'Pillows-for-Pointes', label: 'Pillows for Pointes' },
  { values: 'Prima-Soft', label: 'Prima Soft' },
  { values: 'Russian-Pointe', label: 'Russian Pointe' },
  { values: 'Sansha', label: 'Sansha' },
  { values: 'Stephanie', label: 'Stephanie' },
  { values: 'Suffolk', label: 'Suffolk' },
  { values: 'Sure-Foot', label: 'Sure Foot' },
  { values: 'Sweet-Feet', label: 'Sweet Feet' },
  { values: 'Theatricals', label: 'Theatricals' },
  { values: 'Theatricals-For-Every-Stage', label: 'Theatricals For Every Stage' },
  { values: 'Toe-Saver', label: 'Toe Saver' },
  { values: 'Very-Fine-Shoes', label: 'Very Fine Shoes' },
  { values: 'Wear-Moi', label: 'Wear Moi' },
];

export const sizeOptions = [
  { values: 'One-Size', label: 'One Size' },
  { values: 'Adult-XXS', label: 'Adult XXS' },
  { values: 'X-Small', label: 'Adult XS, (0-2)' },
  { values: 'Small', label: 'Adult S,(4-6)' },
  { values: 'Medium', label: 'Adult M, (6-8)' },
  { values: 'Large', label: 'Adult L, (10-12)' },
  { values: 'X-Large', label: 'Adult XL, (14-16)' },
  { values: '2XL', label: 'Adult 2XL' },
  { values: '3XL', label: 'Adult 3XL' },
  { values: 'Toddler', label: 'Toddler, 2T, 3T' },
  { values: 'Child-X-Small', label: 'Child XS, Child (4,5)' },
  { values: 'Child-Small', label: 'Child S, Child (5-6)' },
  { values: 'Intermediate-Child', label: 'Child Intermediate, Child (6-7)' },
  { values: 'Child-Medium', label: 'Child M, Child(6-8)' },
  { values: 'Child-Large', label: 'Child L, Child(10-12)' },
  { values: 'Child-X-Large', label: 'Child XL, Child(12-14)' },
];

export const conditionOptions = [
  { key: 'Fair', label: 'Fair' },
  { key: 'Good', label: 'Good' },
  { key: 'Like-New', label: 'Like New' },
  { key: 'New', label: 'New' },
];

export const widthOptions = [
  { values: '0x', label: '0x' },
  { values: '1X', label: '1X' },
  { values: '2X', label: '2X' },
  { values: '3X', label: '3X' },
  { values: '4X', label: '4X' },
  { values: 'A', label: 'A' },
  { values: 'B', label: 'B' },
  { values: 'C', label: 'C' },
  { values: 'D', label: 'D' },
  { values: 'E', label: 'E' },
  { values: 'EE', label: 'EE' },
  { values: 'M', label: 'M' },
  { values: 'N', label: 'N' },
  { values: 'OX', label: 'OX' },
  { values: 'W', label: 'W' },
  { values: 'W2', label: 'W2' },
  { values: 'W3', label: 'W3' },
  { values: 'W4', label: 'W4' },
  { values: 'W5', label: 'W5' },
  { values: 'W6', label: 'W2' },
  { values: 'WW', label: 'WW' },
  { values: 'X', label: 'X' },
  { values: 'X0', label: 'X0' },
  { values: 'X1', label: 'X1' },
  { values: 'X2', label: 'X2' },
  { values: 'X3', label: 'X3' },
  { values: 'X4', label: 'X4' },
  { values: 'X5', label: 'X5' },
  { values: 'X6', label: 'X6' },
  { values: 'XN', label: 'XN' },
  { values: 'XX', label: 'XX' },
  { values: 'XXN', label: 'XXN' },
  { values: 'XXX', label: 'XXX' },
  { values: 'XXXN', label: 'XXXN' },
];

export const shankOptions = [
  { values: 'F', label: 'F' },
  { values: 'FX', label: 'FX' },
  { values: 'Flexible Medium', label: 'Flexible Medium' },
  { values: 'Flexible Soft', label: 'Flexible Soft' },
  { values: 'Full Normal', label: 'Full Normal' },
  { values: 'Full Reinforced', label: 'Full Reinforced' },
  { values: 'Full Soft', label: 'Full Soft' },
  { values: 'H', label: 'H' },
  { values: 'HD', label: 'HD' },
  { values: 'Hard', label: 'Hard' },
  { values: 'Light', label: 'Light' },
  { values: 'Medium', label: 'Medium' },
  { values: 'P', label: 'P' },
  { values: 'Regular', label: 'Regular' },
  { values: 'S', label: 'S' },
  { values: 'SF', label: 'SF' },
  { values: 'Soft', label: 'Soft' },
  { values: 'Standard', label: 'Standard' },
  { values: 'X', label: 'X' },
];

export const shoesConditionsOptions = [
  { values: 'Fair', label: 'Fair' },
  { values: 'Good', label: 'Good' },
  { values: 'Like-New', label: 'Like New' },
  { values: 'New', label: 'New' },
  // { values: 'Donate', label: 'Donate' },
  // { values: 'Needs-Work', label: 'Needs Work' },
];

export const colorOptions = [
  { values: 'Ballet-Pink', label: 'Ballet Pink' },
  { values: 'Beige', label: 'Beige' },
  { values: 'Black', label: 'Black' },
  { values: 'Blue', label: 'Blue' },
  { values: 'Bronze', label: 'Bronze' },
  { values: 'Brown', label: 'Brown' },
  { values: 'Burgundy', label: 'Burgundy' },
  { values: 'Classical-Pink', label: 'Classical Pink' },
  { values: 'Coral', label: 'Coral' },
  { values: 'Cream', label: 'Cream' },
  { values: 'Floral', label: 'Floral' },
  { values: 'Gold', label: 'Gold' },
  { values: 'Gray', label: 'Gray' },
  { values: 'Green', label: 'Green' },
  { values: 'Mint', label: 'Mint' },
  { values: 'Multi-Colored', label: 'Multi Colored' },
  { values: 'Nude', label: 'Nude' },
  { values: 'Orange', label: 'Orange' },
  { values: 'Pattern', label: 'Pattern' },
  { values: 'Peach', label: 'Peach' },
  { values: 'Periwinkle', label: 'Periwinkle' },
  { values: 'Pink', label: 'Pink' },
  { values: 'Print', label: 'Print' },
  { values: 'Purple', label: 'Purple' },
  { values: 'Red', label: 'Red' },
  { values: 'Sand', label: 'Sand' },
  { values: 'Silver', label: 'Silver' },
  { values: 'Tan', label: 'Tan' },
  { values: 'Teal', label: 'Teal' },
  { values: 'Theatrical-Pink', label: 'Theatrical Pink' },
  { values: 'Turquoise', label: 'Turquoise' },
  { values: 'White', label: 'White' },
  { values: 'Yellow', label: 'Yellow' },
];

export const shoesColorOptions = [
  { values: 'Black', label: 'Black' },
  { values: 'Blue', label: 'Blue' },
  { values: 'Bronze', label: 'Bronze' },
  { values: 'Brown', label: 'Brown' },
  { values: 'Gold', label: 'Gold' },
  { values: 'Gray', label: 'Gray' },
  { values: 'Green', label: 'Green' },
  { values: 'Multi-Color', label: 'Multi-Color' },
  { values: 'Nude', label: 'Nude' },
  { values: 'Orange', label: 'Orange' },
  { values: 'Pink', label: 'Pink' },
  { values: 'Purple', label: 'Purple' },
  { values: 'Red', label: 'Red' },
  { values: 'Silver', label: 'Silver' },
  { values: 'Tan', label: 'Tan' },
  { values: 'White', label: 'White' },
  { values: 'Yellow', label: 'Yellow' },
];

export const typeOptions = [
  { values: 'Leotard', label: 'Leotard' },
  { values: 'Warmups', label: 'Warmups' },
  { values: 'Contemporary', label: 'Contemporary Sets' },
  // { values: 'Skirts', label: 'Skirts' },
  { values: 'Unitard/Biketard', label: 'Unitard/Biketard' },
  { values: 'Tops', label: 'Tops' },
  { values: 'Bottoms', label: 'Bottoms' },
  { values: 'Skirt', label: 'Skirts' },
  { values: 'Tights', label: 'Tights' },
  { values: 'Accessories', label: 'Accessories' },
  { values: 'Undergarments', label: 'Undergarments' },
];

export const shoesTypeOptions = [
  { values: 'pointe', label: 'Pointe' },
  { values: 'technique', label: 'Technique' },
  { values: 'character', label: 'Character' },
  { values: 'ballet', label: 'Ballet' },
  { values: 'ballroom', label: 'Ballroom' },
  { values: 'cheer-Team', label: 'Cheer Team' },
  { values: 'fitness', label: 'Fitness' },
  { values: 'gymnastics', label: 'Gymnastics' },
  { values: 'hip-hop', label: 'Hip Hop' },
  { values: 'jazz', label: 'Jazz' },
  { values: 'lyrical', label: 'Lyrical' },
  { values: 'modern', label: 'Modern' },
  { values: 'other', label: 'Other' },
  { values: 'praise', label: 'Praise' },
  { values: 'tap', label: 'Tap' },
];

// Costumes Types
export const costumeTypeOptions = [
  { values: 'Biketard', label: 'Biketard' },
  { values: 'Bottoms', label: 'Bottoms' },
  { values: 'Classical-Tutu', label: 'Classical Tutus' },
  { values: 'Complete-Looks', label: 'Complete Looks' },
  { values: 'Dress', label: 'Dress' },
  { values: 'Jackets', label: 'Jackets' },
  { values: 'Leotards', label: 'Leotards' },
  { values: 'Sets', label: 'Sets' },
  { values: 'Tops', label: 'Tops' },
  { values: 'Tutus', label: 'Tutus' },
  { values: 'Unitard', label: 'Unitard' },
];

// Costumes Genres
export const costumeGenreOptions = [
  { values: 'Ballet', label: 'Ballet' },
  { values: 'Tap-And-Jazz', label: 'Tap and Jazz' },
  { values: 'Contemporary-and-Lyrical', label: 'Contemporary and Lyrical' },
  { values: 'Boys-and-Unisex', label: 'Boys and Unisex' },
];

// Costumes Brand Type
export const costumeBrandTypeOptions = [
  { values: 'Catalog', label: 'Catalog' },
  { values: 'Custom', label: 'Custom' },
];

// Costumes Brand
export const costumeBrandOptions = [
  { values: 'Balera', label: 'Balera' },
  { values: 'Capezio', label: 'Capezio' },
  { values: 'Costume-Gallery', label: 'Costume Gallery' },
  { values: 'Custom', label: 'Custom' },
  { values: 'Curtain-Call', label: 'Curtain Call' },
  { values: 'First-Position', label: 'First Position' },
  { values: 'Other', label: 'Other' },
  { values: 'Revolution-Dance', label: 'Revolution Dance' },
  { values: 'Weissman', label: 'Weissman' },
];

// Costumes Colors
export const costumeColorOptions = [
  { values: 'Black', label: 'Black' },
  { values: 'Blue', label: 'Blue' },
  { values: 'Bronze', label: 'Bronze' },
  { values: 'Brown', label: 'Brown' },
  { values: 'Burgundy', label: 'Burgundy' },
  { values: 'Coral', label: 'Coral' },
  { values: 'Cream', label: 'Cream' },
  { values: 'Floral', label: 'Floral' },
  { values: 'Gold', label: 'Gold' },
  { values: 'Gray', label: 'Gray' },
  { values: 'Green', label: 'Green' },
  { values: 'Mint', label: 'Mint' },
  { values: 'Multi-Colored', label: 'Multi-Colored' },
  { values: 'Nude', label: 'Nude' },
  { values: 'Orange', label: 'Orange' },
  { values: 'Peach', label: 'Peach' },
  { values: 'Periwinkle', label: 'Periwinkle' },
  { values: 'Pink', label: 'Pink' },
  { values: 'Print', label: 'Print' },
  { values: 'Purple', label: 'Purple' },
  { values: 'Red', label: 'Red' },
  { values: 'Silver', label: 'Silver' },
  { values: 'Tan', label: 'Tan' },
  { values: 'Teal', label: 'Teal' },
  { values: 'Turquoise', label: 'Turquoise' },
  { values: 'White', label: 'White' },
  { values: 'Yellow', label: 'Yellow' },
];

// Costumes Listing Type
export const costumeListingTypeOptions = [
  { values: 'Individual', label: 'Individual' },
  { values: 'Lot', label: 'Lot' },
  { values: 'Accessories', label: 'Accessories' },
];

// Costumes Sizes
export const costumeSizeOptions = [
  { values: 'One-Size', label: 'One Size' },
  { values: 'Adult-XXS', label: 'Adult XXS' },
  { values: 'Adult-X-Small', label: 'Adult XS, (0-2)' },
  { values: 'Adult-Small', label: 'Adult S,(4-6)' },
  { values: 'Adult-Medium', label: 'Adult M, (6-8)' },
  { values: 'Adult-Large', label: 'Adult L, (10-12)' },
  { values: 'Adult-X-Large', label: 'Adult XL, (14-16)' },
  { values: 'Adult-XXL', label: 'Adult 2XL' },
  { values: 'Adult-3XL', label: 'Adult 3XL' },
  { values: 'Toddler', label: 'Toddler, 2T, 3T' },
  { values: 'Child-X-Small', label: 'Child XS, Child (4,5)' },
  { values: 'Child-Small', label: 'Child S, Child (5-6)' },
  { values: 'Intermediate-Child', label: 'Child Intermediate, Child (6-7)' },
  { values: 'Child-Medium', label: 'Child M, Child(6-8)' },
  { values: 'Child-Large', label: 'Child L, Child(10-12)' },
  { values: 'Child-X-Large', label: 'Child XL, Child(12-14)' },
  { values: 'Multiple-Sizes', label: 'Multiple Sizes' },
];

export const categoriesOptions = [
  { values: profileFilter.COSTUMES, label: 'Costumes' },
  { values: profileFilter.DANCEWEAR, label: 'Dancewear' },
  { values: profileFilter.SHOES, label: 'Shoes' },
  // { values: profileFilter.COSTUMES, label: 'Costumes' },
];

export const shopByCategory = [
  { name: 'Leotards', image: leotards, search: '?pub_category=dancewear&pub_type=Leotard' },
  { name: 'Warmups', image: warmups, search: '?pub_category=dancewear&pub_type=Warmups' },
  { name: 'Shoes', image: shoes, search: '?pub_category=shoes' },
  { name: 'Shop All', image: shopAll, search: '' },
];

export const shopByBrands = [
  { name: 'Yumiko', image: yumiko, search: '?pub_brand=Yumiko' },
  { name: 'Eleve', image: eleve, search: '?pub_brand=Eleve' },
  { name: 'LuckyLeo', image: luckyleo, search: '?pub_brand=Luckyleo-Dancewear' },
  { name: 'Bloch', image: bloch, search: '?pub_brand=Bloch' },
  { name: 'Mariia', image: mariia, search: '?pub_brand=Mariia' },
  { name: 'Ainsliewear', image: ainsliewear, search: '?pub_brand=Ainsliewear' },
  { name: 'Capezio', image: capezio, search: '?pub_brand=Capezio' },
  { name: 'Wearmoi', image: wearmoi, search: '?pub_brand=Wear-Moi' },
];

export const quickFilters = [
  // { name: 'New With Tags', icon: <IconNew />, search: 'pub_condition=New' },
  // { name: 'Free Shipping', icon: <IconFreeShipping />, search: 'pub_shippingEnabled=false' },
  {
    name: 'TTL Encore',
    icon: <img src={logo} alt="Tutulist Encore" style={{ width: '24px', height: '24px' }} />,
    search: 'pub_tutulistEncore=true',
  },
  // {
  //   name: 'PayPal',
  //   icon: (
  //     <img
  //       src={paypal}
  //       alt="Paypal"
  //       style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
  //     />
  //   ),
  //   search: 'pub_paypalAccepted=true',
  // },
  // {
  //   name: 'Adult X-Small',
  //   icon: (
  //     <img
  //       src={xs}
  //       alt="X Small"
  //       style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
  //     />
  //   ),
  //   search: 'pub_size=X-Small',
  // },
  // {
  //   name: 'Adult Small',
  //   icon: (
  //     <img src={s} alt="Small" style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }} />
  //   ),
  //   search: 'pub_size=Small',
  // },
  // {
  //   name: 'Adult Medium',
  //   icon: (
  //     <img
  //       src={medium}
  //       alt="Medium"
  //       style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
  //     />
  //   ),
  //   search: 'pub_size=Medium',
  // },
  // {
  //   name: 'Adult Large',
  //   icon: (
  //     <img
  //       src={large}
  //       alt="Large"
  //       style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
  //     />
  //   ),
  //   search: 'pub_size=Large',
  // },
  // {
  //   name: 'Adult X-Large',
  //   icon: (
  //     <img
  //       src={xl}
  //       alt="X Large"
  //       style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
  //     />
  //   ),
  //   search: 'pub_size=X-Large',
  // },

  // { name: 'Shipping', icon: <IconShipping />, search: '?pub_shippingEnabled=true' },
  // { name: 'Black', icon: <IconBlack />, search: '?pub_color=Black' },
  // { name: 'Blue', icon: <IconBlue />, search: '?pub_color=Blue' },
  // { name: 'Pink', icon: <IconPink />, search: '?pub_color=Pink' },
  // { name: 'Purple', icon: <IconPurple />, search: '?pub_color=Purple' },
];

export const filterSliderResponsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1701,
    },
    items: 5,
  },
  tablet: {
    breakpoint: {
      max: 1700,
      min: 1421,
    },
    items: 4,
  },
  mobile: {
    breakpoint: {
      max: 1420,
      min: 1331,
    },
    items: 4,
  },
  XSmobile: {
    breakpoint: {
      max: 1330,
      min: 1281,
    },
    items: 4,
  },
  XXSmobile: {
    breakpoint: {
      max: 1280,
      min: 1201,
    },
    items: 4,
  },
  XXXSmobile: {
    breakpoint: {
      max: 1200,
      min: 1024,
    },
    items: 4,
  },
  XXXXSmobile: {
    breakpoint: {
      max: 1023,
      min: 901,
    },
    items: 5,
  },
  XXXXXSmobile: {
    breakpoint: {
      max: 900,
      min: 781,
    },
    items: 5,
  },
  XXXXXXSmobile: {
    breakpoint: {
      max: 780,
      min: 768,
    },
    items: 6,
  },
};

export const costumesQuickFilters = [
  { name: 'Free Shipping', icon: <IconFreeShipping />, search: 'pub_shippingEnabled=false' },
  {
    name: 'PayPal',
    icon: (
      <img
        src={paypal}
        alt="Paypal"
        style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_paypalAccepted=true',
  },
  {
    name: 'Custom',
    icon: (
      <img
        src={customIcon}
        alt="Custom Costumes"
        style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_brandType=Custom',
  },
  {
    name: 'Catalog',
    icon: (
      <img
        src={catalogueIcon}
        alt="Costumes Catalog"
        style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_brandType=Catalog',
  },
  {
    name: 'Single Costume',
    icon: (
      <img
        src={singleIcon}
        alt="Single Costume"
        style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_listingType=Individual',
  },
  {
    name: 'Costume Lots',
    icon: (
      <img
        src={lotsIcon}
        alt="Costume Lots"
        style={{ width: '32px', height: '28px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_listingType=Lot',
  },
  {
    name: 'Ballet',
    icon: (
      <img
        src={balletIcon}
        alt="Ballet"
        style={{ width: '27px', height: '27px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_genre=Ballet',
  },
  {
    name: 'Contemporary',
    icon: (
      <img
        src={contemporaryIcon}
        alt="Contemporary"
        style={{ width: '27px', height: '27px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_genre=Contemporary-and-Lyrical',
  },
  {
    name: 'Jazz',
    icon: (
      <img
        src={jazzIcon}
        alt="Jazz"
        style={{ width: '24px', height: '24px', filter: 'opacity(0.5) ' }}
      />
    ),
    search: 'pub_genre=Tap-And-Jazz',
  },
];

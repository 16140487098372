import { fetchCurrentUser } from '../../ducks/user.duck';
import { ShippoApi } from '../../util/api';
import { RESPONSE, TUTULIST_LOWERCASE } from '../../util/enums';
import { getPublicDataAttributeValue, isArrayLength } from '../../util/dataExtractors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const FETCH_LISTINGS_IN_PROGRESS = 'app/ShippingDetailsPage/FETCH_LISTINGS_IN_PROGRESS';
export const FETCH_LISTINGS_SUCCESS = 'app/ShippingDetailsPage/FETCH_LISTINGS_SUCCESS';

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ShippingDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ShippingDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ShippingDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ShippingDetailsPage/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ShippingDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/ShippingDetailsPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/ShippingDetailsPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/ShippingDetailsPage/RESET_PASSWORD_ERROR';

export const CREATE_SHIPPO_ADDRESS_REQUEST =
  'app/ShippingDetailsPage/CREATE_SHIPPO_ADDRESS_REQUEST';
export const CREATE_SHIPPO_ADDRESS_SUCCESS =
  'app/ShippingDetailsPage/CREATE_SHIPPO_ADDRESS_SUCCESS';
export const CREATE_SHIPPO_ADDRESS_ERROR = 'app/ShippingDetailsPage/CREATE_SHIPPO_ADDRESS_ERROR';

export const SAVE_LISTINGS_RESPONSE = 'app/ShippingDetailsPage/SAVE_LISTINGS_RESPONSE';
export const SAVE_WEIGHT_IN_PROGRESS = 'app/ShippingDetailsPage/SAVE_WEIGHT_IN_PROGRESS';

export const CLEAR_FORM = 'app/ShippingDetailsPage/CLEAR_FORM';

// ================ Reducer ================ //

const initialState = {
  saveShippoAddressInProgress: false,
  shippoAddressSaved: false,
  saveShippoAddressError: null,
  clearForm: false,
  saveWeightInProgress: false,
  fetchListingsInProgress: false,
  listingsFetched: false,
  saveWeightInProgress: false,
  allListings: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_IN_PROGRESS:
      return {
        ...state,
        fetchListingsInProgress: true,
      };
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, listingsFetched: false, fetchListingsInProgress: false };
    case CREATE_SHIPPO_ADDRESS_REQUEST:
      return {
        ...state,
        saveShippoAddressInProgress: true,
      };
    case CREATE_SHIPPO_ADDRESS_SUCCESS:
      return { ...state, saveShippoAddressInProgress: false, shippoAddressSaved: true };
    case CREATE_SHIPPO_ADDRESS_ERROR:
      return {
        ...state,
        saveShippoAddressInProgress: false,
        saveShippoAddressError: true,
      };
    case SAVE_LISTINGS_RESPONSE:
      return { ...state, allListings: payload.data };
    case CLEAR_FORM:
      return { ...state, clearForm: true };
    case SAVE_WEIGHT_IN_PROGRESS:
      return { ...state, saveWeightInProgress: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchListingsInProgress = () => ({ type: FETCH_LISTINGS_IN_PROGRESS });
export const fetchListingsSuccess = () => ({ type: FETCH_LISTINGS_SUCCESS });

export const createShippoAddressRequest = () => ({ type: CREATE_SHIPPO_ADDRESS_REQUEST });
export const createShippoAddressSuccess = () => ({ type: CREATE_SHIPPO_ADDRESS_SUCCESS });
export const createShippoAddressError = () => ({ type: CREATE_SHIPPO_ADDRESS_ERROR });
export const clearForm = () => ({ type: CLEAR_FORM });
export const saveWeightInProgress = () => ({ type: SAVE_WEIGHT_IN_PROGRESS });

export const saveListingsResponse = response => ({
  type: SAVE_LISTINGS_RESPONSE,
  payload: { data: response },
});

// ================ Thunks ================ //

export const createShippoAddress = params => async (dispatch, getState, sdk) => {
  dispatch(createShippoAddressRequest());
  try {
    const address = Object.keys(params).length && (await ShippoApi.createAddressWithShippo(params));
    dispatch(createShippoAddressSuccess());
    return address;
  } catch (error) {
    dispatch(createShippoAddressError());
  }
};

export const saveWeightToAllListings = params => async (dispatch, getState, sdk) => {
  dispatch(saveWeightInProgress());
  const listings = getState().ShippingDetailsPage.allListings;

  const isShippingParams = params?.isShippingParams;

  try {
    for (let index = 0; index < listings.length; index++) {
      const listing = listings[index];

      const updateParams = isShippingParams
        ? {
            shippingPriceInSubunitsOneItem: params?.shippingPriceInSubunitsOneItem,
            shippingPriceInSubunitsAdditionalItems: params?.shippingPriceInSubunitsOneItem,
            deliveryOptions: params?.deliveryOptions,
          }
        : {
            length: '8.5',
            width: '11',
            height: '1',
            shippingPriceInSubunitsOneItem: null,
            shippingPriceInSubunitsAdditionalItems: null,
            deliveryOptions: ['tutulist'],
            weight: params?.weight,
            massUnit: params?.massUnit,
          };

      const updatedListing = await sdk.ownListings.update({
        id: listing?.id?.uuid,
        publicData: { ...updateParams },
      });
    }
    return RESPONSE.SUCCESS;
  } catch (error) {
    dispatch(createShippoAddressError());
  } finally {
    dispatch(clearForm());
  }
};

export const fetchCurrentUserListings = (id, tab) => async (dispatch, getState, sdk) => {
  const allListings = [];
  dispatch(fetchListingsInProgress());
  const extraParams = { authorId: id, include: ['currentStock'] };
  const isTTL = tab === TUTULIST_LOWERCASE;

  try {
    const listingsResponse = await sdk.listings.query({
      ...extraParams,
    });

    const queryNextListings = async (page, totalPages) => {
      const newPage = page + 1;
      const res = await sdk.listings.query({ page: newPage, ...extraParams });
      const listingsData = res?.data?.data;
      dispatch(saveListingsResponse(listingsData));
      dispatch(addMarketplaceEntities(res));
      allListings.push(listingsData);
      const { totalItems = 0 } = meta;
      if (totalItems > 0 && totalPages !== newPage) {
        queryNextListings(newPage, totalPages);
      }
    };

    const meta = listingsResponse?.data?.meta;
    const data = listingsResponse?.data?.data;
    const page = meta && meta.page;
    const { totalPages = 0, totalItems = 0 } = meta;
    allListings.push(data);

    if (totalItems > 0 && totalPages !== page) {
      await queryNextListings(page, totalPages);
    }
    dispatch(saveListingsResponse(data));
    dispatch(addMarketplaceEntities(listingsResponse));
    if (isTTL) {
      const listings = allListings.flat();

      const isWeightMissing = isArrayLength(listings)
        ? !!listings?.find(l => !getPublicDataAttributeValue('weight', l))
        : false;
      return {
        weightMissingAndTTLShipping: isWeightMissing,
      };
    } else if (!isTTL) {
      const listings = allListings.flat();
      const currentPageResultIds = isArrayLength(listings) ? listings?.map(l => l.id) : [];
      currentPageResultIds;

      //PageListing is used so we have combined current stock relationship
      const pageListings =
        currentPageResultIds &&
        currentPageResultIds.length &&
        getListingsById(getState(), currentPageResultIds);

      const isShippingPriceMissing = isArrayLength(listings)
        ? !!listings?.find(l => !getPublicDataAttributeValue('deliveryOptions', l))
        : false;

      const isAnyStockGreaterThanOne = isArrayLength(pageListings)
        ? pageListings.find(item => item?.currentStock?.attributes?.quantity > 1)
        : false;

      return {
        weightMissingAndOwnShipping: isShippingPriceMissing,
        isAnyStockGreaterThanOne: !!isAnyStockGreaterThanOne,
      };
    }
  } catch (error) {
    dispatch(createShippoAddressError());
  } finally {
    dispatch(fetchListingsSuccess());
  }
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

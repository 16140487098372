import unionWith from 'lodash/unionWith';
import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { profileFilter } from '../../config/enums';
import { isArrayLength } from '../../util/dataExtractors';
import { customSortListingsByKeyword } from '../../util/genericHelpers';
import { queryTransaction, queryUser } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { LISTING_QUERY_PARAMS, TUTULIST_USER_ID } from '../../util/enums';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages

const RESULT_PAGE_SIZE = 30;

// Function to calculate pagination meta
function calculatePaginationMeta(itemsLength) {
  const totalPages = Math.ceil(itemsLength / RESULT_PAGE_SIZE);
  return {
    totalItems: itemsLength,
    totalPages,
    page: 1,
    paginationLimit: totalPages,
    perPage: RESULT_PAGE_SIZE,
  };
}

const extraParams = {
  perPage: RESULT_PAGE_SIZE,
  include: ['author', 'images', 'author.profileImage'],
  'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
  'fields.image': [
    `variants.listing-card`,
    `variants.listing-card-2x`,
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  ...createImageVariantConfig(`listing-card`, 400, 1),
  ...createImageVariantConfig(`listing-card-2x`, 800, 1),
  'limit.images': 5,
};

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/StorePage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/StorePage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/StorePage/SEARCH_LISTINGS_ERROR';

export const SAVE_LIKED_LISTINGS_REQUEST = 'app/StorePage/SAVE_LIKED_LISTINGS_REQUEST';
export const SAVE_LIKED_LISTINGS_SUCCESS = 'app/StorePage/SAVE_LIKED_LISTINGS_SUCCESS';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/StorePage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/StorePage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/StorePage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/StorePage/SEARCH_MAP_SET_ACTIVE_LISTING';
export const SAVE_LISTINGS_STATE = 'app/StorePage/SAVE_LISTINGS_STATE';

export const QUERY_REVIEWS_SUCCESS = 'app/StorePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/StorePage/QUERY_REVIEWS_ERROR';
export const SHOW_USER_SUCCESS = 'app/StorePage/SHOW_USER_SUCCESS';

export const SOLD_ITEM_REQUEST = 'app/StorePage/SOLD_ITEM_REQUEST';
export const SOLD_ITEM_SUCCESS = 'app/StorePage/SOLD_ITEM_SUCCESS';

export const QUERY_RENTAL_LISTINGS_REQUEST = 'app/StorePage/QUERY_RENTAL_LISTINGS_REQUEST';
export const QUERY_RENTAL_LISTINGS_SUCCESS = 'app/StorePage/QUERY_RENTAL_LISTINGS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  searchAllListingsInProgress: true,
  reviews: [],
  queryReviewsError: null,
  userId: null,
  likedListingSearchInProgress: false,
  currentPageLikedResultIds: [],
  soldListings: [],
  currentPageRentalResultIds: [],
  rentalListingsQueryInProgress: false,
};

const resultIds = data => data?.data?.map(l => l?.id);
const resultInfiniteScrollIds = (data, oldData) => [...oldData, ...data.data.map(al => al.id)];

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_LIKED_LISTINGS_REQUEST:
      return {
        ...state,
        likedListingSearchInProgress: true,
      };
    case QUERY_RENTAL_LISTINGS_REQUEST:
      return { ...state, rentalListingsQueryInProgress: true };
    case QUERY_RENTAL_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageRentalResultIds: resultIds(payload?.data),
        rentalListingsQueryInProgress: false,
      };
    case SOLD_ITEM_REQUEST:
      return { ...state, fetchingSoldInProgress: true, soldListings: [] };
    case SOLD_ITEM_SUCCESS:
      return {
        ...state,
        soldListings: payload?.soldListings?.map(item => item?.id) || [],
        fetchingSoldInProgress: false,
      };
    case SAVE_LIKED_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageLikedResultIds: resultIds(payload?.data),
        pagination: payload.data.meta,
        likedListingSearchInProgress: false,
      };

    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        currentPageResultIds: [],
        searchListingsError: null,
        searchAllListingsInProgress: true,
      };
    case SEARCH_LISTINGS_SUCCESS:
      let currentPageResultIds = resultInfiniteScrollIds(payload.data, state.currentPageResultIds);
      currentPageResultIds = currentPageResultIds.filter(
        (cpri, index, self) => index === self.findIndex(t => t.uuid === cpri.uuid)
      );
      return {
        ...state,
        currentPageResultIds,
        pagination: payload.data.meta,
        searchInProgress: false,
      };

    case SAVE_LISTINGS_STATE:
      return {
        ...state,
        currentPageResultIds: payload.currentPageResultIds,
        pagination: payload.pagination,
        searchInProgress: false,
      };

    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload.reviews, reviewCount: payload.reviewCount };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SHOW_USER_SUCCESS:
      return { ...state, userId: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const saveLikedListingsRequest = () => ({ type: SAVE_LIKED_LISTINGS_REQUEST });

export const saveLikedListingsSuccess = response => ({
  type: SAVE_LIKED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const saveListingState = response => ({
  type: SAVE_LISTINGS_STATE,
  payload: { currentPageResultIds: response.currentPageResultIds, pagination: response.pagination },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryRentalListingsRequest = () => ({ type: QUERY_RENTAL_LISTINGS_REQUEST });

export const queryRentalListingsSuccess = response => ({
  type: QUERY_RENTAL_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsSuccess = payload => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const showUserSuccess = payload => ({
  type: SHOW_USER_SUCCESS,
  payload,
});

export const soldItemRequest = () => ({
  type: SOLD_ITEM_REQUEST,
});

export const soldItemSuccess = params => ({
  type: SOLD_ITEM_SUCCESS,
  payload: params,
});

// ================ Selectors ================ //

export const searchInProgressSelector = state => {
  return state.StorePage.searchInProgress;
};

export const reviewCountSelector = state => {
  return state.StorePage.reviewCount;
};

export const reviewsSelector = state => {
  return state.StorePage.reviews;
};

export const profileUserIdSelector = state => {
  return state.StorePage.userId;
};

// ================  ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage', 'profile.bio', 'profile.displayName', 'profile.publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess(userId));
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const querySoldItems = userId => async (dispatch, getState, sdk) => {
  dispatch(soldItemRequest());

  if (!userId) {
    console.error('Invalid user ID');
    return;
  }

  try {
    const { soldListings = [] } = await queryTransaction({ userId });

    if (!Array.isArray(soldListings) || !soldListings.length) {
      dispatch(soldItemSuccess({ soldListings: [] }));
      return;
    }

    const listings = soldListings
      .map(item => {
        dispatch(addMarketplaceEntities(item));
        return item?.data?.data;
      })
      .flat();

    dispatch(
      soldItemSuccess({
        soldListings: listings,
      })
    );
  } catch (error) {
    console.error(`Error while fetching sold items for user ${userId}`);
  }
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);

        // Fire and forget the transaction query and we query for TTL using cron at 00:00 and 12:00
        dispatch(querySoldItems(userId));
  
      // Continue with dispatching the success action
      dispatch(
        queryReviewsSuccess({ reviews, reviewCount: response?.data?.meta?.totalItems || 0 })
      );
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const searchAuthorLikedListings = ids => async (dispatch, getState, sdk) => {
  dispatch(saveLikedListingsRequest());
  const params = {
    ids,
    ...LISTING_QUERY_PARAMS,
  };
  return sdk.listings
    .query(params)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(saveLikedListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const queryRentalListings = authorId => async (dispatch, getState, sdk) => {
  dispatch(queryRentalListingsRequest());
  const params = {
    author_id: authorId,
    pub_isRental: true,
    ...LISTING_QUERY_PARAMS,
  };
  return sdk.listings
    .query(params)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryRentalListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const searchListings = (searchParams, isInfiniteScroll = false, extraParams) => async (
  dispatch,
  getState,
  sdk
) => {
  const saveScroll =
    typeof window !== 'undefined' && window.sessionStorage.getItem('saveScroll')
      ? Boolean(window.sessionStorage.getItem('saveScroll'))
      : false;

  const hasListingsAlready = getState().SearchPage.currentPageResultIds;
  const pagination = getState().SearchPage.pagination;

  if (isArrayLength(hasListingsAlready) && saveScroll) {
    dispatch(
      saveListingState({
        currentPageResultIds: hasListingsAlready,
        pagination,
      })
    );
    typeof window !== 'undefined' && window.sessionStorage.removeItem('saveScroll');
  }

  !isInfiniteScroll && !saveScroll && dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const { perPage, price, dates, sort, pub_category, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

  const profileParams = extraParams?.params;
  const profileState = extraParams?.state;

  const username = profileParams?.username;
  const profileId = profileState?.userId;
  const userId = !profileId && username ? await queryUser({ username }) : profileId;

  if (!userId && typeof window !== 'undefined') {
    window.location.href = '/';
  }

  const params = {
    ...rest,
    ...priceMaybe,
    ...sortMaybe,
    per_page: perPage,
    author_id: userId,
  };

  //Forcefully deleting the params.params and params.state keys as they are not needed in the query
  delete params.params;
  delete params.state;

  return sdk.listings
    .query(params)
    .then(async response => {
      const listings = response?.data?.data || [];

      if (isArrayLength(listings) && params?.keywords) {
        const sortedListings = customSortListingsByKeyword(listings, params);
        response.data.data = sortedListings;
        response.data.meta = calculatePaginationMeta(sortedListings.length);
      }

      dispatch(queryUserReviews(userId));
      dispatch(showUser(userId));

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, state) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, ...rest } = queryParams;

  return searchListings(
    {
      ...minStockMaybe,
      ...rest,
      page,
      ...extraParams,
    },
    false, // Is used for infinite scroll
    { state, params }
  );
};

export const loadMore = (params, extraParams) => {
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  return searchListings(
    {
      ...minStockMaybe,
      ...params,
      ...extraParams,
    },
    true, // Is used for infinite scroll
    extraParams
  );
};

import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getBookmarks, isArrayLength } from '../../util/dataExtractors';
import { FEATURED_BRAND } from '../../util/enums';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages

const extraParams = {
  include: ['author', 'images', 'author.profileImage', 'currentStock'],
  'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
  'fields.image': [
    `variants.listing-card`,
    `variants.listing-card-2x`,
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  ...createImageVariantConfig(`listing-card`, 400, 1),
  ...createImageVariantConfig(`listing-card-2x`, 800, 1),
  'limit.images': 5,
};

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/CartPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/CartPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_FEATURED_LISTINGS_SUCCESS = 'app/CartPage/SEARCH_FEATURED_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/CartPage/SEARCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  currentFeaturedPageResultIds: [],
  searchAllListingsInProgress: true,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        currentFeaturedPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };

    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = response => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: response,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const searchFeaturedListingsSuccess = response => ({
  type: SEARCH_FEATURED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = () => async (dispatch, getState, sdk) => {
  const isAuthenticated = getState().Auth.isAuthenticated;

  dispatch(searchListingsRequest(true));

  let bookmarkIds =
    typeof window !== 'undefined' &&
    window.localStorage &&
    JSON.parse(window.localStorage.getItem('localBookmarks'));

  if (isAuthenticated) {
    const userResponse = await sdk.currentUser.show();
    const currentUser = userResponse.data.data;
    bookmarkIds = getBookmarks(currentUser);
  }

  const params = {
    ...extraParams,
    page: 1,
    per_page: 100,
    minStock: 1,
  };

  try {
    let response;

    if (isArrayLength(bookmarkIds)) {
      const paramsWithIds = { ...params, ids: bookmarkIds.slice(0, 99) };

      response = await sdk.listings.query(paramsWithIds);
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
    }

    const featuredListingsResponse = await sdk.listings.query({
      ...extraParams,
      page: 1,
      per_page: 40,
      minStock: 1,
      sort: '-createdAt',
      pub_brand: FEATURED_BRAND.join(','),
    });

    dispatch(addMarketplaceEntities(featuredListingsResponse));
    dispatch(searchFeaturedListingsSuccess(featuredListingsResponse));

    return response || featuredListingsResponse;
  } catch (error) {
    dispatch(searchListingsError(storableError(error)));
    throw error;
  }
};

export const loadData = () => {
  return searchListings();
};
